<template>
  <page-footer>
    <template v-slot:right>
      <div class="d-flex align-items-center">
        <div class="mr-4 d-flex align-items-center">
          <div class="text-truncate">{{ $t("compute.text_286") }}</div>
          <div class="ml-2 prices">
            <template v-if="price">
              <template v-if="isMgEcloud && isPrepaid">
                <span class="hour">{{ price }}</span>
              </template>
              <template v-else>
                <div class="hour d-flex">
                  <span>{{ priceText }}</span>
                  <span>{{ price }}</span>
                </div>
                <div class="hour d-flex">
                  <span>eip服务</span>
                  <span>{{ serverPrice }}</span>
                </div>
              </template>
            </template>
            <div v-else class="tips text-truncate">
              <span v-html="priceTips" />
            </div>
          </div>
        </div>
      </div>
      <div class="btns-wrapper d-flex align-items-center">
        <a-button
          class="ml-3"
          type="primary"
          native-type="submit"
          html-type="submit"
          @click="handleConfirm"
          :loading="loading">{{ $t("common_258") }}</a-button>
      </div>
    </template>
  </page-footer>
</template>
<script>
import _ from 'lodash'
import * as R from 'ramda'
import { mapGetters } from 'vuex'
import { findPlatform } from '@/utils/common/hypervisor'
// import DiscountPrice from '@/sections/DiscountPrice'
import { hasMeterService } from '@/utils/auth'
import { PriceFetcherByPriceKey } from '@/utils/common/price'
import { numerify } from '@/filters'
import { CHARGE_TYPE } from '@Network/constants'
export default {
  name: 'BottomBar',
  components: {
    // DiscountPrice,
  },
  inject: ['form', 'cloudEnv'],
  props: {
    currentCloudregion: {
      type: Object,
    },
    size: {
      type: Number,
    },
    bgpType: {
      required: true,
    },
    isHCSO: {
      required: false,
      default: false,
    },
    isMgEcloud: {
      type: Boolean,
      required: false,
      default: false,
    },
    cloudAccountId: String,
  },
  data () {
    this._getPrice = _.debounce(this._getPrice, 500)
    return {
      loading: false,
      priceObj: null,
      currency: '¥',
      discount: 0,
      price: null,
      serverPrice: null,
      chargeType: '',
      isPrepaid: false,
      originPrice: null,
      priceTips: '--',
      hasMeterService,
      curPrice: null,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    priceText () {
      return this.chargeType === CHARGE_TYPE.bandwidth
        ? this.$t('network.text_484')
        : '流量'
    },
  },
  watch: {
    currentCloudregion (value) {
      if (value?.provider) {
        this._getPrice()
        return
      }
      if (value?.external_id) {
        this._getPrice()
      }
    },
    size () {
      this._getPrice()
    },
    bgpType () {
      this._getPrice()
    },
    cloudAccountId () {
      this._getPrice()
    },
  },
  created () {
    this._getPrice()
  },
  methods: {
    // 金额千分位
    priceFormat (price, unit) {
      const str = price.toString()
      const ary = str.split('.')
      let len = 0
      if (ary.length > 1) {
        len = ary[1].length
      }
      let u = '0,0.00'
      if (len > 2) {
        for (let i = 0; i < len - 2; i++) {
          u += '0'
        }
      }
      price = numerify(price, u)
      return `${this.currency} ${price}${unit}`
    },
    // 获取eip的总价明细展示
    async getEipPrice (values) {
      const manager = new this.$Manager('price_eips/price', 'v2')
      const { data = {} } = await manager.list({
        params: {
          area_mapping_id: values.cloudregion,
          bandwidth: values.bandwidth,
          type: values.charge_type,
          billing_type: values.billing_type,
          duration: values.duration,
        },
      })
      this.curPrice = data
      this.isPrepaid = values.billing_type === 'prepaid'
      this.chargeType = values.charge_type
      let priceUnit = '/GB'
      let serverPriceUnit = '/小时'
      let serverPrice = data.server_price_hour
      let price = data.traffic_price
      if (this.chargeType === CHARGE_TYPE.bandwidth) {
        priceUnit = this.isPrepaid ? '' : '/天'
        serverPriceUnit = '/天'
        serverPrice = data.server_price_day
        price = this.isPrepaid
          ? data.bandwidth_price_month
          : data.bandwidth_price_day
      }
      this.price = this.priceFormat(price, priceUnit)
      this.serverPrice = this.priceFormat(serverPrice, serverPriceUnit)
    },
    resetPrice () {
      this.priceObj = null
      this.currency = '¥'
      this.discount = 0
      this.price = null
      this.originPrice = null
      this.priceTips = '--'
    },
    async _getPrice () {
      try {
        if (R.isEmpty(this.currentCloudregion) || !hasMeterService()) return
        if (!this.size) {
          this.resetPrice()
          return
        }
        let region = ''
        let externalProvider = ''
        if (this.currentCloudregion.external_id) {
          const arr = this.currentCloudregion.external_id.split('/')
          region = arr[1]
          externalProvider = arr[0]
        }
        const provider = externalProvider || this.currentCloudregion.provider
        const env = findPlatform(provider)
        if (env === 'private') return // 私有云暂时不支持EIP价格查询

        let bgpType = this.bgpType || ''
        if (provider.toLowerCase() === 'huawei') {
          if (
            ['cn-southwest-2', 'cn-north-1', 'cn-east-2', 'cn-south-1'].indexOf(
              region,
            ) >= 0
          ) {
            bgpType = '19_sbgp'
          } else if (region === 'cn-northeast-1') {
            bgpType = '19_telcom'
          } else {
            bgpType = '19_bgp'
          }
        } else if (provider.toLowerCase() === 'aliyun') {
          bgpType = 'bgp'
        }

        let price_key = `${provider}::${region}::::bandwidth::${bgpType}`
        if (provider.toLowerCase() === 'onecloud') {
          price_key = `${provider}::${region}::::eip::bandwidth${
            this.bgpType ? '.' + this.bgpType : ''
          }`
        }
        const pf = new PriceFetcherByPriceKey({
          scope: this.$store.getters.scope,
          priceKey: price_key,
          amount: this.size,
          cloudaccountId: this.cloudAccountId,
        })

        const p = await pf.getPriceObj()
        this.currency = p.currency
        this.discount = p.discount
        this.price = p.price
        this.originPrice = p.originPrice
        this.priceTips = p.priceTips
      } catch (err) {
        throw err
      }
    },
    doCreate (data) {
      return new this.$Manager('eips').create({ data })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        values.cur_price = this.curPrice
        values.domain = values.domain?.key
        values.tenant = values.project?.key
        Reflect.deleteProperty(values, 'project')
        // 防止bandwidth为空
        values.bandwidth = values.bandwidth || 1
        if (this.cloudEnv === 'private' && !this.isHCSO) {
          delete values.charge_type
          values.bandwidth = 0
          if (values.ip_addr) {
            values.ip = values.ip_addr
            delete values.ip_addr
          }
        }
        values.area_mapping_id = values.cloudregion
        values.replace_list_key = 'cloudregion,manager'
        delete values.cloudregion
        await this.doCreate(values)
        this.loading = false
        this.$message.success(this.$t('k8s.text_184'))
        this.$router.push('/eip')
      } catch (error) {
        this.loading = false
        throw error
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../../../../src/styles/less/theme";

.prices {
  .hour {
    color: @error-color;
    font-size: 14px;
  }
  .tips {
    color: #999;
    font-size: 12px;
  }
}
</style>
